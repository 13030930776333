.alt-font {
  font-family: 'Playfair Display', serif;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  display: inline-block;
  background-color: #0058A6;
  line-height: 1.375;
  font-family: inherit;
  cursor: pointer;
  color: #fff;
  padding: 10px 14px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
}
.button:hover,
.button:focus {
  background-color: #CCCCCC;
}
.container--head {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 15px;
}
.container-—mood {
  padding: 30px 15px 0;
}
.divider {
  width: calc(100% - 30px);
  margin: 10px 15px 20px;
}
.container--content {
  padding: 0 95px 100px;
}
.footarea {
  padding: 0 95px;
}
.vcard {
  display: flex;
  gap: 30px;
}
.adr,
.contact {
  width: 25%;
}
.contact {
  margin-top: 0;
}
.area--one {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.area--one .unitOne {
  margin-left: 15px;
  margin-right: 15px;
}
.area--one .unitOne--1-1 {
  width: calc(100% - 30px);
}
.area--one .unitOne--1-2 {
  width: calc(50% - 30px);
}
.area--one .unitOne--1-3 {
  width: calc((100% / 3) - 30px);
}
@media (max-width: 991px) {
  .area--one .unitOne--1-3 {
    width: 100%;
  }
}
.area--one .unitOne--2-3 {
  width: calc((100% / 3 * 2) - 30px);
}
.area--one .unitOne--1-1 .unit__body,
.area--one .unitOne--1-2 .unit__body,
.area--one .unitOne--2-3 .unit__body {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.area--one .unitOne--1-1 .part,
.area--one .unitOne--1-2 .part,
.area--one .unitOne--2-3 .part {
  margin-left: 15px;
  margin-right: 15px;
}
.area--one .unitOne--1-1 .part--1-1,
.area--one .unitOne--1-2 .part--1-1,
.area--one .unitOne--2-3 .part--1-1 {
  width: calc(100% - 30px);
}
.area--one .unitOne--1-1 .part--1-2,
.area--one .unitOne--1-2 .part--1-2,
.area--one .unitOne--2-3 .part--1-2 {
  width: calc(50% - 30px);
}
/*# sourceMappingURL=./screen-medium.css.map */